@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.privacity {
    font-family: 'Inter', sans-serif;
    color: #454849;
    padding: 0 1rem;
    
    h2 {
        display: block;
        font-weight: bold;
        font-size: 2em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    h3 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        border-bottom: 1px solid #1dbec7;
    }

    h4 {
        display: block;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        font-size: 1.1rem;
    }

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: .9rem;
    }

    ul, .ulDiv {
        display: block;
        list-style-type: disc;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    .ulDiv {
        list-style-type: circle;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent;
    }

    a {
        text-decoration: none;
        color: #454849;

        &:hover {
            transition: all .5s ease-in;
        }
    }
}

@media(max-width: 500px) {
    .privacity {
        ul {
            padding-inline-start: 10px;
        }
    }
}
@media(min-width: 1100px) and (max-width: 1599px) {
    .privacity {
        padding: 0 10rem;
    }
}
@media(min-width: 1600px) {
    .privacity {
        padding: 0 25rem;
    }
}