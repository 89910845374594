.container {
    font-family: 'Roboto', sans-serif;

    .shape{
        overflow: hidden;
        position: absolute;
        left: 0;
        width: 100%;
        line-height: 0;
    }

    .banner {
        background-color: #450372;
        height: 30vh;
        padding: 0 10rem;
        margin-top: 4rem;

        .lefttext {
            color: var(--white);
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 30vh;

            h1 {
                font-size: 2.5rem;
                line-height: 3rem;
                color:#FF9900;
            }

            p {
                font-size: 1.5rem;
                margin-top: 1rem;
                color: aliceblue;
            }
        }
    }

    label{
        display:block;
    }

    .formcontrol{
        width:500px;
        height:100px;

        span{
            display:block;
            color: red;
        }
    }

    .input {
        color: var(--black);
        transition: 250ms;
        outline: 0;
        border: 1px solid var(--gray-200);
        width: 68%;
        height: 2.5rem;
        border-radius: .3rem;
        padding-left: .7rem;

        &::placeholder {
          color: var(--gray-200);
        }

        &:focus {
          border-color: var(--black);
        }
      }

    .inputButton {
        background-color: #FF9900;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 250ms;
        font-size: 1rem;
        border-top-right-radius: .3rem;
        border-bottom-right-radius: .3rem;
        height: 2.4rem;
        width: 9rem;

        &:hover {
          filter: brightness(.9);
        }

        &:disabled{
            background-color: #f5c680;
            color:#ffdddd;
        }
      }

    .buttons {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        .button {
          background: var(--primary);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2.7rem;
          width: 12rem;
          border-radius: .3rem;
          
          &:nth-child(2) {
            margin-left: 1rem;
          }
        }
      }

    section {
        width: 100%;
        padding:150px 5rem 200px 5rem;

        .paragrafo{
            line-height: 1.5em;
            margin:0 0 40px 0;
            width:75vw;
        }
        
        .content {
            padding: 2rem 1rem;
            transition: 250ms;

            .header {
                display: flex;
                align-items: center;
                margin-bottom: 2rem;
                
                .search {
                    color: var(--black);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid var(--gray-200);
                    width: 12rem;
                    height: 2.3rem;
                    border-radius: .2rem;
                    padding: 0 .4rem;

                    input {
                        background-color: transparent;
                        outline: 0;
                        border: 0px;
                        width: 100%;
                        height: 2.3rem;

                        &::placeholder {
                            color: var(--gray-200);
                        }
                    }
                }
            }

            .categorias {
                .title {
                    display: flex;
                    align-items: center;
                    margin-top: 2rem;

                    span {
                        font-weight: bold;
                        margin-left: .7rem;
                    }
                }
    
                .list {
                    display: flex;
                    width: 100%;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    padding-bottom: 2rem;
    
                    .item {
                        color: var(--black);
                        cursor: pointer;
                        margin-top: .7rem;
                        height: 21rem;

                        &:nth-child(n + 2) {
                            margin-left: 1rem;
                        }
    
                        img {
                            border-radius: .4rem;
                            width: 300px;
                            height: 250px;
                        }
    
                        p {
                            display: block;
                            word-wrap:break-word;
                            margin-top: .3rem;
                            width: 18.8rem;
                        }
    
                        .itemfooter {
                            display: flex;
                            align-items: center;
                            margin-top: .5rem;
    
                            span {
                                color: var(--black);
                                font-weight: bold;
                                text-transform: capitalize;
                                margin-left: .4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .container {
        .banner {
            .lefttext {
                align-items: center;
                width: 100%;

                h1 {
                    text-align: center;
                    width: auto;
                }

                p {
                    text-align: center;
                    width: auto;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media(max-width: 1150px) {
    .container {
        .banner {
            padding: 0 5rem;
        }
    }
}

.warning{
    background-color: #00ff66;
    color: #0e7b3a;
    padding:20px;
    margin:20px 0;
    border-radius: 5px;
}