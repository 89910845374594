$primary: #fbad47;
$secondary: #1dbec7;
$tertiary: #454849;
$mainmenucolor: #1c9292;
$tmain: #9695a4;
$tsec: #d9d3dd;
$tline: lighten( $tsec, 10%);
$tbg: rgba(0,0,0,.5);

#template{
    margin: 0 3rem;
    height:600px;
    border:3px solid #080808;
    border-bottom-width:16px;
    box-shadow: inset 0px 0px 4px 3px rgba(0,0,0,0.2);
    border-radius: 20px;
    background: #e0e0e0;
    overflow:hidden;
    position:relative;
    
    #content{
        display: flex;
    }

    #header{
        background: $tmain;
        height:50px;
        margin-top:0px;
    }
    
    #main{
        flex:1 0 80%;
    }

    #side{
        flex:1 0 20%;
        display:none;
    }


    #title{
        background: $tsec;
        height:30px;
        width:90%;
        margin:0 auto;
        margin-top:50px;
    }

    #subtitle{
        background: $tsec;
        height:10px;
        width:90%;
        margin:0 auto;
        margin-top:10px;
    }

    #side{
        margin-top:50px;
    }

    .line, .linef{
        background: $tline;
        height:10px;
        margin:0px auto 10px auto;
    }
    .line{
        width:90%;
    }
    .linef{
        width:30%;
    }

    .news{
        background: $tsec;
        height:100px;
        width: 90%;
        margin:0px auto 10px auto;
    }
    
    #image{
        background: $tmain;
        height:200px;
        width:90%;
        margin:20px auto;
        overflow:hidden;
        position: relative;
    }

    
    .intercontentclose{
        color:#FFF;
        font-size:12px;
        float:right;
        margin:20px 20px 0 0;
    }
    
    .alertclose, .alertalphaclose{
        color:#FFF;
        font-size:10px;
        float:right;
        margin:5px 10px 0 0;
    }
    
    .ftclose{
        color:#FFF;
        font-size:12px;
        float:right;
        margin:5px 10px 0 0;
        transform: translate(40px, -10px)
    }

}

@keyframes ani-templatemobile{
    from{ width:600px }
    to{ width:320px }
}

.templatemobile{
    width:320px;
    animation: ani-templatemobile 500ms;
}

@keyframes ani-templatedesktop{
    from{ width:320px }
    to{ width:600px }
}

.templatedesktop{
    width:600px;
    animation: ani-templatedesktop 500ms;
}

@keyframes ani_intercontent{
    0% { width:10px; height:10px; left:50%; top:50%; opacity:0 }
    80% { width:10px; height:10px; left:50%; top:50%; opacity:0 }
    100% { width:100%; height:100%; left:0px; top:0px; opacity:1 }
}

.intercontent{
    width:100%;
    height:100%;
    background: $primary;
    position: absolute;
    top:0px;
    left:0px;
    opacity: 1;
    animation: ani_intercontent 3s ease-in;

    .topbar{
        background:$secondary;
        height:60px;
    }
    
}

@keyframes ani_alert{
    0% { right:-200px; opacity:0 }
    80% { right:-200px; opacity:0 }
    100% { right:0px; opacity:1 }
}

.alert{
    width:200px;
    height:150px;
    background: $primary;
    position: absolute;
    top:60px;
    right:0px;
    opacity: 1;
    animation: ani_alert 1s ease-out;

    .topbar{
        background:$secondary;
        height:20px;
    }
}

@keyframes ani_preparagraph{
    from { height:0px; opacity:0 }
    to { height:90px; opacity:1 }
}

.preparagraph{
    width:90%;
    height:90px;
    margin:0 auto;
    background: $primary;
    opacity: 1;
    animation: ani_preparagraph 1s ease-out;

    .topbar{
        background:$secondary;
        height:20px;
    }
}


@keyframes ani_ft{
    from { bottom:-90px; opacity:0 }
    to { bottom:0px; opacity:1 }
}

.ft{
    width:100%;
    height:90px;
    background: $tbg;
    position: absolute;
    bottom:0px;
    left:0px;
    opacity: 1;
    animation: ani_ft 1s ease-out;

    .topbar{
        background:$primary;
        height:80px;
        width:80%;
        margin:5px auto;
    }
}

@keyframes ani_io{
    from { top:-60px; opacity:0 }
    to { top:0px; opacity:1 }
}

.io{
    width:100%;
    height:55px;
    margin:0 auto;
    background: $tbg;
    opacity: 1;
    padding-top:5px;
    position: absolute;
    animation: ani_io 1s ease-out;

    .ads{
        background:$primary;
        height:50px;
        width:80%;
        margin:0px auto;
    }
}


@keyframes ani_dt{
    from { height:0px; width:0%; opacity:0 }
    to { height:90px; width:90%; opacity:1 }
}

.dt, .dtm{
    width:90%;
    height:90px;
    margin:10px auto;
    background: $primary;
    opacity: 1;
    animation: ani_dt 1s ease-out;

    .topbar{
        background:$secondary;
        height:20px;
    }
}

@keyframes ani_alertalpha{
    from { right:-200px; opacity:0 }
    to { right:0px; opacity:1 }
}

.alertalpha{
    width:180px;
    height:150px;
    background: $primary;
    position: absolute;
    top:60px;
    right:0px;
    opacity: 1;
    animation: ani_alertalpha 1s ease-out;
    border-top-left-radius:80px;

    .topbar{
        background:$secondary;
        height:20px;
        position: absolute;
        bottom:0px;
        width:100%;
    }
}


@keyframes ani_chatads{
    from { right:-200px; opacity:0 }
    to { right:0px; opacity:1 }
}

.chatads{
    width:100px;
    height:50px;
    border-radius:5px;
    background: $primary;
    position: absolute;
    top:540px;
    right:10px;
    opacity: 1;
    animation: ani_chatads 1s ease-out;

    .topbar{
        display: none;
    }
}


@keyframes ani_rewards{
    from { width:10px; height:10px; left:50%; top:50%; opacity:0 }
    to { width:100%; height:100%; left:0px; top:0px; opacity:1 }
}

@keyframes ani_rewardsmodal{
    from { width:10px; height:10px; left:50%; top:50%; opacity:0 }
    to { width:400px; height:400px; opacity:1 }
}

.rewards{
    width:100%;
    height:100%;
    background: $tbg;
    position: absolute;
    top:0px;
    left:0px;
    opacity: 1;
    animation: ani_rewards 1s ease-in;

    .topbar{
        background: $primary;
        height: 400px;
        width: 400px;
        margin: 100px auto;
        border-radius: 5px;
        animation: ani_rewardsmodal 1s ease-in;
    }
    
}

.btn{
    border:none;
    padding:15px 30px;
    color:$tertiary;
    border-radius: 5px;

    &:focus{
        border:none;
        outline:none;
    }
}

.btn-selected{
    background-color:$tertiary;
    color: #FFFFFF;
    border-radius: 5px;
}


@media(min-width: 500px) and (max-width: 899px) {
    #template {
        margin: 0 1rem;
    }
}

@media(max-width: 900px) {
    .templatemobile {
        width: 250px;
        height: 500px !important;
    }

    .templatedesktop {
        width: 370px;
    }
}