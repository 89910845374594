html, body{
    padding:0px;
    margin:0px;
}
body{
    margin-top: 4rem;
}

#template{
    background: #e0e0e0;
    overflow:hidden;
    position:relative;
}

.frame{
    margin: 0 auto;
    width:320px;
    height:600px;
    border:3px solid #080808;
    border-bottom-width:16px;
    box-shadow: inset 0px 0px 4px 3px rgba(0,0,0,0.2);
    border-radius: 20px;
}
#content{
    display: flex;
    background: #e0e0e0;
}

#header{
    background: #9695a4;
    height:50px;
    margin-top:0px;
}

#main{
    flex:1 0 80%;
}

#side{
    flex:1 0 20%;
    display:none;
}


#title{
    background: #d9d3dd;
    height:30px;
    width:90%;
    margin:0 auto;
    margin-top:50px;
}

#subtitle{
    background: #d9d3dd;
    height:10px;
    width:90%;
    margin:0 auto;
    margin-top:10px;
}

#side{
    margin-top:50px;
}

.line, .linef{
    background: #fbf5ff;
    height:10px;
    margin:0px auto 10px auto;
}
.line{
    width:90%;
}
.linef{
    width:30%;
    margin-bottom:15px;
}

.news{
    background: #d9d3dd;
    height:100px;
    width: 90%;
    margin:0px auto 10px auto;
}

#image{
    background: #9695a4;
    height:200px;
    width:90%;
    margin:20px auto;
    overflow:hidden;
    position: relative;
}

@media screen and (min-width: 600px) {
    #side{
        display:block;
    }
    .line, .linef{
        height:20px;
    }
    #image{
        height:300px;
    }
    .news{height:300px;}
    #header{
        height:100px;
    }
}