#nextcase {
    animation: swipetoleft .5s;
}

#previouscase {
    animation: swipetoright .5s;
}

@keyframes swipetoleft {
    0% { transform: translateX(75%); opacity: 0;}
    100% { transform: translateX(0); opacity: 1;}
}

@keyframes swipetoright {
    0% { transform: translateX(-50%); opacity: 0;}
    100% { transform: translateX(0); opacity: 1;}
}