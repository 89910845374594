@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.container {

    // Esse Header é a configuração do Header da seção de Formatos e Cases, que tem o mesmo design.
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            display: flex;
            align-items: center;

            .line {
                background-color: var(--exeprim);
                width: 1.5rem;
                height: .5px;
            }

            h2 {
                color: var(--exeprim);
                margin-left: 1rem;
            }
        }

        .menunavigation {
            display: flex;
            background-color: var(--exeprim);
            height: 2.5rem;
            width: 6rem;
            border-radius: 2rem;

            .line {
                background-color: #ffffff50;
                width: 1px;
                height: 2.5rem;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: .25s;
                width: 3rem;

                &:hover {
                    background-color: var(--exeprimhover);
                }
            }

            .menuleft {
                border-radius: 2rem 0 0 2rem;
            }

            .menuright {
                border-radius: 0 2rem 2rem 0;
            }
        }
    }

    .banner {
        // background: linear-gradient(259.43deg, #4495E0 5.47%, #7644E0 91.85%);
        position: relative;
        background-image: url("../../assets/images/background.png");
        background-size: cover;
        height: 80vh;
        
        // .bg {
        //     background-color: #fff;
        //     position: absolute;
        //     bottom: -1px;
        //     height: 50px;
        //     width: 100%;
        //     clip-path: polygon(100% 100%, 0 100%, 0 0);
        // }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .text {
                color: var(--exesecon);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 40%;
                margin-top: 10rem;
                
                h1 {
                    text-align: center;
                    font-size: 60px;
                    line-height: 68px;

                    span {
                        // background-color: var(--exeprim);
                        color: var(--exesecon);
                        padding: 0 7px;
                    }
                }

                p {
                    font-size: 1.8rem;
                    margin-top: 1rem;
                    text-align: center;
                }

                .button {
                    background-color: var(--exeprim) !important;
                    color: var(--exesecon);
                    background: linear-gradient(var(--exeprimhover) 0 0) no-repeat calc(200% - var(--p, 0%)) 100% / 200% var(--p, 0.3rem);
                    transition: 0.3s var(--t, 0s), background-position 0.3s calc(0.3s - var(--t, 0s));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    cursor: pointer;
                    width: 18rem;
                    height: 2.5rem;
                    border-radius: .7rem;
                    margin-top: 1rem;
                    
                    &:hover {
                        --p: 100%;
                        --t: 0.3s;
                        color: #fff;
                    }
                }
            }

            .examples {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: -14.7rem;
                margin: 0 2rem;
                width: 100%;

                .menuleft, .menuright {
                    background-color: var(--exeprim);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: .25s;
                    width: 3.5rem;
                    height: 3.5rem;
                    margin: 0 1rem;
                    border-radius: 1rem;

                    &:hover {
                        background-color: var(--exeprimhover);
                    }
                }

                .formatos {
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    overflow-x: scroll;
                    scroll-behavior: smooth;
                    margin: 2rem 1rem;
                    padding-bottom: 1.5rem;
                    width: 100%;

                    &::-webkit-scrollbar {
                        width: 0;
                    }

                    .card {
                        display: flex;
                        flex-direction: column;
                        width: 15rem;
                        height: 23rem;
                        border-radius: .5rem;
        
                        &:nth-child(n + 2) {
                            margin-left: 2rem;
                        }
        
                        .top {
                            background-color: var(--exeprimhover);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 3rem;
                            border-radius: .5rem .5rem 0 0;
        
                            span {
                                color: var(--exesecon);
                            }
                        }
        
                        .content {
                            background-color: var(--exeprim);
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 1rem 0;
                            min-height: 15rem;
                            border: 1px solid var(--exeprim);
                            border-bottom: 0;
                            border-top: 0;

                            p {
                                color:var(--exesecon);
                                font-weight: bold;
                                text-align: center;
                                font-size: 1rem;
                                width: 90%;
                                height: 3rem;
                            }

                            svg {
                                margin-top: .7rem;
                            }
                        }
        
                        .bottom {
                            display: flex;
                            width: 15rem;
        
                            a {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-decoration: none;
                                cursor: pointer;
                                transition: .25s;
                                padding: 0 .75rem;
                                height: 3rem;
                                font-size: .9rem;
                            }
        
                            .buttonespec {
                                background-color: var(--exesecon);
                                background-image: linear-gradient(var(--exeprimhover), var(--exeprimhover));
                                background-size: 0 100%;
                                background-repeat: no-repeat;
                                border: 1px solid var(--exeprim);
                                border-radius: 0 0 0 .5rem;
                                width: 9rem;
                                transition: .4s;
                                border-right: 0;

                                &:hover {
                                    background-size: 100% 100%;
                                    border-color: var(--exeprimhover);
        
                                    span {
                                        color: var(--exesecon);
                                    }
                                }
        
                                span {
                                    transition: .4s;
                                    color: var(--exeprim);
                                }
                            }
        
                            .buttonprev {
                                background-image: linear-gradient(var(--exeprimhover),var(--exeprimhover));
                                background-size: 0 100%;
                                background-repeat: no-repeat;
                                border: 1px solid var(--exeprim);
                                border-radius: 0 0 .5rem 0;
                                transition: .25s;
                                width: 6rem;
                                transition: .4s;
        
                                &:hover {
                                    background-size: 100% 100%;
                                    border: 1px solid var(--exeprimhover);

                                    span {
                                        color: var(--exesecon);
                                    }
                                }
        
                                span {
                                    color: var(--exeprim);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cases {
        // background: linear-gradient(259.43deg, #4495E0 5.47%, #7644E0 91.85%);
        background-color: var(--exesecon);
        position: relative;
        overflow: hidden;
        padding: 2rem 5rem;
        margin-top: 20rem;

        .content {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;
            height: 15rem;

            .left {
                .devices {
                    background-color: var(--exeprim);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: .5rem;

                    .line {
                        background-color: var(--exesecon);
                        height: 1.5rem;
                        width: 1px;
                    }

                    .device {
                        color: var(--exesecon);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .4s;
                        padding: 0 1rem;
                        border-radius: .5rem;
                        width: 100%;
                        height: 2rem;
                        text-decoration: none;

                        &:hover {
                            background-color: var(--exeprimhover);
                        }

                        &:last-child {
                            border-radius: 0 .5rem .5rem 0;
                        }

                        span {
                            font-size: .9rem;
                            margin-left: .5rem;
                        }
                    }
                }

                .text {
                    margin-top: 1rem;

                    h2 {
                        margin: 0;
                        display: block;
                    }

                    p {
                        margin: 0;
                    }
                }
            }
        }

        .right {
            .image {
                img {
                    border: 1px solid #000;
                    border-radius: .3rem;
                }
            }
        }
    }
}


@media(max-width: 400px) {
    .container {
        .banner {
            .content {
                .examples {
                    .formatos {
                        margin: 2rem 0;

                        .card {
                            width: 20rem;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 800px) {
    .container {
        .banner {
            .content {
                .text {
                    width: 95%;

                    h1 {
                        font-size: 45px;
                        line-height: 50px;
                    }

                    p {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .cases {
            padding: 2rem 1rem 5rem 1rem;

            .content {
                flex-direction: column;
                margin-top: 1rem;

                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .text {
                    text-align: center;
                }

                .right {
                    .image {
                        margin-top: 1rem;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 1200px) and (max-width: 1599px) {
    .container {
        .cases {
            padding: 2rem 10rem;
        }
    }
}

@media(min-width: 800px) and (max-width: 1199px) {
    .container {
        .banner {
            .content {
                .text {
                    width: 80%;
                }
            }
        }
    }
}

@media(min-width: 1080px) {
    .container {
        .banner {
            .content {
                .examples {
                    width: auto;

                    .menuleft, .menuright {
                        display: none;
                    }

                    .formatos {
                        justify-content: center;
                        overflow-x: hidden;
                    }
                }
            }
        }
    }
}

@media(min-width: 1200px) and (max-width: 1750px) {
    .container {
        .banner {
            .content {
                .text {
                    width: 60%;
                }
            }
        }
    }
}

@media(min-width: 1600px) {
    .container {
        .examples {
            padding: 3rem 15rem;
        }

        .cases {
            padding: 2rem 15rem;
        }
    }
}

@media(min-width: 1900px) {
    .container {
        .examples {
            padding: 3rem 25rem;
        }

        .cases {
            padding: 2rem 25rem;
        }
    }
}

@media(min-width: 2150px) {
    .container {
        .examples {
            padding: 3rem 35rem;
        }

        .cases {
            padding: 2rem 35rem;
        }
    }
}

@media(max-height: 670px) {
    .container {
        .banner {
            height: 110vh;

            .content {
                .text {
                    margin-top: 6rem;
                }
            }
        }
    }
}


@media(min-height: 700px) and (max-height: 720px) {
    .container {
        .banner {
            .content {
                .text {
                    margin-top: 6rem;
                }
            }
        }
    }
}

@media(min-height: 721px) and (max-height: 741px) {
    .container {
        .banner {
            height: 110vh;

            .content {
                .text {
                    margin-top: 6rem;
                }
            }
        }
    }
}

@media(min-height: 799px) and (max-height: 899px) {
    .container {
        .banner {
            height: 90vh;
        }
    }
}

@media(min-height: 1001px) and (max-height: 1200px) {
    .container {
        .banner {
            height: 60vh;
        }
    }
}

@media(min-height: 1250px) {
    .container {
        .banner {
            height: 50vh;
        }
    }
}