@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.container {
  font-family: 'Roboto', sans-serif;

  .banner {
    height: 80vh;
    background: url('../../assets/images/main.jpg') no-repeat;
    background-size: cover;

    .innerBanner {
      background: rgba(0, 0, 0, .4);
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80vh;
      padding: 0 5rem;

      h1 {
        color: var(--white);
        font-size: 3.5rem;
        width: 80%;
        line-height: 4rem;
      }

      p {
        color: var(--gray-100);
        font-weight: lighter;
        width: 50%;
        margin-top: 1rem;
        font-size: 1.5rem;

        a {
          color: var(--white);
          font-weight: bold;
          text-decoration: none;
          transition: 250ms;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        .button {
          background: var(--primary);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2.7rem;
          width: 12rem;
          border-radius: .3rem;
          
          &:nth-child(2) {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;

    h2 {
      color: var(--black);
      text-align: center;
      font-weight: lighter;
      font-size: 2rem;
    }

    p {
      color: var(--gray-900);
      text-align: center;
      margin-top: 1rem;
      width: 60%;
    }

    .secondaryColor {
      color: var(--black);
      margin-top: 2rem;
    }
  }

  .newsletter {
    background: linear-gradient(90deg, #665498 0%, #88D3CE 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;

    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 80%;
      
      h2 {
        color: #fff;
      }
  
      .wrappedInput {
        display: flex;
        align-items: center;
        margin-top: .7rem;
  
        input {
          outline: 0;
          border: 0;
          height: 2.4rem;
          width: 22rem;
          border-top-left-radius: .3rem;
          border-bottom-left-radius: .3rem;
          padding-left: .7rem;
        }
  
        .inputButton {
          background-color: #FF9900;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 250ms;
          font-size: 1rem;
          border-top-right-radius: .3rem;
          border-bottom-right-radius: .3rem;
          height: 2.4rem;
          width: 9rem;
  
          &:hover {
            filter: brightness(.9);
          }
        }
      }
    }

    p {
      color: #fff;
      width: 31rem;
      margin-top: 1rem;
      font-size: 1rem;
    }
  }

  .membership {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inlineItem {
      display: flex;
      align-items: center;
      animation: fadefromright 1s forwards;
      width: 1100px;

      &:nth-child(2) {
        flex-direction: row-reverse;
        animation: fadefromleft 1s forwards;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 550px;

        p {
          color: var(--gray-900);
          text-align: center;
          width: 80%;
        }

        .buttonOutlinePrimary, .buttonOutlineSecondary {
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          text-decoration: none;
          transition: 250ms;
          margin-top: 2rem;
          padding: 0 1rem;
          height: 2.3rem;
          border: 1px solid var(--primary);
          border-radius: .3rem;
          
          &:hover {
            background: var(--primary);

            span {
              color: var(--white);
            }
          }

          span {
            color: var(--black);
            transition: 250ms;
          }
        }

        .buttonOutlineSecondary {
          border-color: var(--secondary);

          &:hover {
            background: var(--secondary);
          }
        }
      }

      img {
        object-fit: cover;
      }
    }
  }

  .contact {
    display: flex;
    align-items: center;
    padding: 3rem 0;
    margin-top: 1rem;

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 50%;

      span {
        color: var(--black);
        display: flex;
        align-self: flex-start;
        text-align: left;
        font-weight: bold;
        font-size: 1.2rem;
      }

      .input {
        color: var(--black);
        transition: 250ms;
        outline: 0;
        border: 1px solid var(--gray-200);
        width: 68%;
        height: 2.5rem;
        border-radius: .3rem;
        padding-left: .7rem;

        &:nth-child(n + 2) {
          margin-top: 1rem;
        }

        &::placeholder {
          color: var(--gray-200);
        }

        &:focus {
          border-color: var(--black);
        }
      }

      textarea {
        resize: none;
        min-width: 60%;
        min-height: 12rem;
        padding-top: .5rem;
      }

      .submit {
        background: var(--primary);
        color: var(--black);
        display: flex;
        align-self: flex-end;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 250ms;
        height: 2.5rem;
        padding: 0 1rem;
        border-radius: .3rem;
        margin-top: 1rem;

        &:hover {
          filter: brightness(.9);
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      margin-left: 5rem;

      .title {
        font-size: 1.5rem;
        width: 70%;
      }

      p {
        margin-top: .5rem;
        font-size: 1rem;
        color: var(--gray-900);
      }

      .address {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        span {
          font-size: 1rem;

          &:first-child {
            font-weight: bold;
          }

          &:nth-child(n + 2) {
            margin-top: .1rem;
          }
        }
      }

      .contactInfo {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        span {
          font-size: 1rem;
        }

        a {
          color: var(--black);
          text-decoration: none;
          font-size: 1rem;
          width: 11rem;
        }
      }

      .mediakit {
        color: var(--black);
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
        font-size: 1rem;
        margin-top: 2rem;
        width: 12.5rem;
      }
    }
  }
}


/** ---------------------- RESPONSIVIDADE MIN-WIDTH ---------------------- **/
@media (min-width: 1100px) {
  .container {
    .membership {
      padding: 0 10rem;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    .membership {
      padding: 0 16rem;
    }

    p {
      font-size: 1.1rem;
    }
  }
}

@media (min-width: 1700px) {
  .container {
    .membership {
      padding: 0 18rem;
    }
  }
}

/** -------------------- FIM RESPONSIVIDADE MIN-WIDTH -------------------- **/



/** ---------------------- RESPONSIVIDADE MAX-WIDTH ---------------------- **/

@media (max-width: 350px) {
  .container {
    .contact {
      .info {
        width: 90%;
      }
    }
  }
}

@media (max-width: 550px) {
  .container {
    .banner {
      height: 100vh;

      .innerBanner {
        height: 100vh;

        h1 {
          font-size: 2rem;
          line-height: 2.8rem;
        }

        p {
          font-size: 1.1rem;
          width: 100%;
        }
      }
    }

    .membership {
      .inlineItem {
        img {
          height: 300px;
        }
      }
    }

    .newsletter {
      padding: 3rem .7rem;

      h2 {
        text-align: center;
      }

      p {
        text-align: center;
        width: 90%;
      }
    }
  }
}

@media (max-width: 600px) {
  .container {
    .about {
      h2 {
        width: 90%;
        line-height: 2.3rem;
      }
    }

    .membership {
      .inlineItem {
        .text {
          width: 100%;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .container {
    .membership {
      .inlineItem {
        flex-direction: column-reverse;
        width: 100%;

        &:nth-child(2) {
          flex-direction: column-reverse;
          margin-top: 3rem;
        }

        .text {
          margin-top: 1rem;

          p {
            width: 90%;
          }

          .buttonOutlinePrimary, .buttonOutlineSecondary {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .container {
    .contact {
      flex-direction: column;

      form {
        align-items: center;
        width: 90%;

        .input {
          width: 100%;
        }
      }

      .info {
        margin: 2rem 0 0 0;
        width: 90%;

        .title {
          text-align: center;
          width: 100%;
        }

        p {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    .header {
      width: 100%;
      padding: 0 1rem;

      .nav {
        display: none;
      }

      .menuButton {
        display: flex;
        margin-left: auto;
      }
    }

    .banner {
      .innerBanner {
        align-items: center;
        padding: 0 1rem;

        h1 {
          text-align: center;
        }

        p {
          text-align: center;
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 1099px) {
  .container {
    .banner {
      .innerBanner {
        h1 {
          width: 100%;
        }
      }
    }

    .about {
      h2 {
        font-size: 1.8rem;
      }

      p {
        width: 80%;
      }
    }
  }
}
/** -------------------- FIM RESPONSIVIDADE MAX-WIDTH -------------------- **/



/** ---------------------- RESPONSIVIDADE BETWEEN WIDTHS ---------------------- **/
@media (min-width: 551px) and (max-width: 1099px) {
  .container {
    .banner {
      .innerBanner {
        h1 {
          line-height: 3.2rem;
          font-size: 2.5rem;
        }

        p {
          font-size: 1.2rem;
          width: 80%;
        }
      }
    }
  }
}

@media (min-width: 601px) and (max-width: 749px) {
  .container {
    .membership {
      .inlineItem {
        .text {
          width: 80%;
        }

        img {
          width: 80%;
        }
      }
    }
  }
}

@media (min-width: 750px) and (max-width: 850px) {
  .container {
    .membership {
      .inlineItem {
        width: 750px;

        .text {
          width: 375px;
        }

        img {
          width: 375px;
          height: 250px;
        }
      }
    }
  }
}

@media(min-width: 851px) and (max-width: 1099px) {
  .container {
    .membership {
      .inlineItem {
        width:900px;

        .text {
          width: 450px;
        }

        img {
          width: 450px;
          height: 300px;
        }
      }
    }

    .contact {
      form {
        .input {
          width: 90%;
        }

        textarea {
          min-width: 90%;
        }
      }
    }
  }
}

@media (min-width: 851px) and (max-width: 1350px) {
  .container {
    .info {
      margin-left: 1rem;
      padding: 0 1rem;

      .title {
        font-size: 1.3rem;
        width: 100%;
      }
    }

    .contact {
      .info {
        margin-left: 2rem;

        .title {
          width: 100%;
        }

        .address, .contactInfo, .mediakit {
          margin-top: 1.5rem;
        }
      }
    }
  }
}

@media (min-width: 901px) and (max-width: 1699px) {
  .container {
    .header {
      padding: 0 1rem;
    }
  }
}

@media(min-width: 1100px) and (max-width: 1350px) {
  .container {
    .contact {
      form {
        .input {
          width: 75%;
        }

        textarea {
          min-width: 75%;
        }
      }
    }
  }
}
/** -------------------- FIM RESPONSIVIDADE BETWEEN WIDTHS -------------------- **/


@keyframes fadefromleft {
  from { transform: translateX(-50%); opacity: 0; }
  to { transform: translateX(0); opacity: 1 }
}

@keyframes fadefromright {
  from { transform: translateX(50%); opacity: 0 }
  to { transform: translateX(0); opacity: 1 }
}

.bannerexemplo {
  position: relative;
  background-image: url("../../assets/images/background.png");
  background-size: cover;
  height: 60vh;

  @media screen and (max-width: 800px) {
    height: 80vh;
  }
  
  .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .text {
          color: var(--exesecon);
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 80%;
          margin-top: 5rem;
          
          h1 {
              font-size: 60px;
              line-height: 68px;

              span {
                  // background-color: var(--exeprim);
                  color: var(--exesecon);
                  padding: 0 7px;
              }
          }

          p {
              font-size: 1.8rem;
              margin-top: 1rem;
          }

          .button {
              background-color: var(--exeprim) !important;
              color: var(--exesecon);
              background: linear-gradient(var(--exeprimhover) 0 0) no-repeat calc(200% - var(--p, 0%)) 100% / 200% var(--p, 0.3rem);
              transition: 0.3s var(--t, 0s), background-position 0.3s calc(0.3s - var(--t, 0s));
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              cursor: pointer;
              width: 26rem;
              height: 4.5rem;
              border-radius: .7rem;
              margin-top: 5rem;
              font-size: 1.5rem;
              
              &:hover {
                  --p: 100%;
                  --t: 0.3s;
                  color: #fff;
              }
          }
      }

  }
}