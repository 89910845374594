@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.nav-menu {
    background-color: var(--white);
    font-family: 'open sans', sans-serif;
    display: flex;
    flex-direction: column;
    position: absolute;
    transition: all .5s ease;
    opacity: 0;
    width: 20rem;
    height: 100vh;
    top: 4rem;
    left: -100%;
    padding-top: .5rem;
}

.nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all .5s ease;
    z-index: 1;
}

.nav-menu-link {
    color: var(--black);
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-left: 1rem;
}

.nav-menu-link:active {
    color: var(--black);
}

.nav-menu-link:hover {
    color: var(--black);
}

.nav-menu-link:nth-child(n + 2) {
    margin-top: 1rem;
}

.nav-menu.active div, .nav-menu div {
    background-color: var(--gray-100);
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin: 1rem 0;
}

.nav-menu.active img, .nav-menu img {
    width: 32px;
    height: 32px;
}

.rs-picker-toggle-placeholder {
    color: #000 !important;
    border-color: #000 !important;
}

@media(min-width: 901px) {
    .nav-menu {
        display: none;
    }
}