.container {
    font-family: 'Roboto', sans-serif;

    .banner {
        background: url('../../assets/images/bannerbgdocs.jpg');
        background-position: right;
        background-size: cover;
        background-repeat: repeat;
        height: 50vh;
        padding: 0 10rem;
        margin-top: 4rem;

        .lefttext {
            color: var(--white);
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            height: 50vh;

            h1 {
                font-size: 2.5rem;
                width: 20rem;
                line-height: 3rem;
            }

            p {
                font-size: 1.5rem;
                width: 30rem;
                margin-top: 1rem;
            }
        }
    }

    section {
        display: flex;
        width: 100%;
        
        .content {
            padding: 2rem 1rem;
            transition: 250ms;

            .header {
                display: flex;
                align-items: center;
                margin-bottom: 2rem;
                
                .search {
                    color: var(--black);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid var(--gray-200);
                    width: 12rem;
                    height: 2.3rem;
                    border-radius: .2rem;
                    padding: 0 .4rem;

                    input {
                        background-color: transparent;
                        outline: 0;
                        border: 0px;
                        width: 100%;
                        height: 2.3rem;

                        &::placeholder {
                            color: var(--gray-200);
                        }
                    }
                }
            }

            .categorias {
                .title {
                    display: flex;
                    align-items: center;
                    margin-top: 2rem;

                    span {
                        font-weight: bold;
                        margin-left: .7rem;
                    }
                }
    
                .list {
                    display: flex;
                    width: 100%;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    padding-bottom: 2rem;
    
                    .item {
                        color: var(--black);
                        cursor: pointer;
                        margin-top: .7rem;
                        height: 21rem;

                        &:nth-child(n + 2) {
                            margin-left: 1rem;
                        }
    
                        img {
                            border-radius: .4rem;
                            width: 300px;
                            height: 250px;
                        }
    
                        p {
                            display: block;
                            word-wrap:break-word;
                            margin-top: .3rem;
                            width: 18.8rem;
                        }
    
                        .itemfooter {
                            display: flex;
                            align-items: center;
                            margin-top: .5rem;
    
                            span {
                                color: var(--black);
                                font-weight: bold;
                                text-transform: capitalize;
                                margin-left: .4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .container {
        .banner {
            .lefttext {
                align-items: center;
                width: 100%;

                h1 {
                    text-align: center;
                    width: auto;
                }

                p {
                    text-align: center;
                    width: auto;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media(max-width: 1150px) {
    .container {
        .banner {
            padding: 0 2rem;
        }
    }
}
