@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root {
    --primary: #FFB300;
    --secondary: #23bbc8;
    --text: #454849;
    --text-color: #1E9FAB;

    --exeprim: #702632;
    --exeprimhover: #b31f37;
    --exesecon: #fff;
    --exeseconhover: #ffffffa6;
    
    --white: #fff;
    --black: #000;

    --black-100: #111; 
    --black-200: #222;
    --black-600: #666;

    --gray-50: #f7f8fa;
    --gray-100: #e6e8eb;
    --gray-200: #afb2b1;
    --gray-500: #888888;
    --gray-800: #494d4b;
    --gray-900: #535252;
    --gray-icon: #787878;
    --gray-white: #F5F5F5;

    --brown: #322D27;

    --yellow: #F9C122;
    --red: #CA0808;
    --green: #548C1D;
    --pink: #E31C6E;
    
    --purple: #9f75ff;
    --purple-100: #B393FF;
}

@media(min-width: 1100px) {
    p {
        font-size: 1.1rem;
    }
}

@media(max-width: 1099px) {
    p {
        font-size: 1rem;
    }
}
@media(max-width: 720px) {
    html {
        font-size: 85,5%;
    }
}

body {
    background: var(--white);
    font-family: 'Roboto', sans-serif;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 1.5rem;
}

.inputDiv {
    display: flex;
    flex-direction: column;
    margin-top: .7rem;
}

label {
    color: var(--text);
    font-weight: bold;
}

//input {
//    background-color: transparent;
//    color: var(--text);
//    outline: 0;
//    border: 1px solid var(--gray-100);
//    transition: 250ms;
//    width: 12rem;
//    height: 2.4rem;
//    border-radius: .2rem;
//    padding: 0 .6rem;
//
//    &::placeholder {
//        color: rgb(150, 150, 150);
//    }
//
//    &:focus {
//        border: 1px solid rgb(150, 150, 150);
//
//        &::placeholder {
//            color: transparent;
//        }
//    }
//
//    &:disabled {
//        background-color: rgb(210, 210, 210);
//        color: rgb(160, 160, 160);
//
//        &::placeholder {
//            color: rgb(160, 160, 160);
//        }
//    }
//}

@media(max-width: 479px) {
    //input {
    //    height: 2.5rem;
    //}
}