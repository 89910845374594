.menu {
    background-color: var(--gray-white);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: 250ms;

    .toggleButton {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 250ms;
        height: 3rem;
        width: 3rem;

        &:hover {
            transform: scale(1.2);
        }
    }

    .list {
        flex-direction: column;
        width: 100%;
        
        li {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: 250ms;
            padding: 0 1rem;
            height: 3rem;

            &:hover {
                background-color: var(--gray-100);
            }


            span {
                color: var(--black);
                margin-left: .7rem;
            }
        }
    }

    .listMobile {
        flex-direction: column;
        transition: 250ms;
        width: 3rem;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            cursor: pointer;
            width: 3rem;
            height: 3rem;

            &:hover {
                background-color: var(--gray-100);
            }
        }
    }
}
