.header {
  background: var(--white);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 4rem;
  padding: 0 2rem;
  z-index: 9999;

  .logoHeader {
    cursor: pointer;
  }

  .logoMenu {
    width: 32px;
    height: 32px;
  }

  .nav {
    margin-left: auto;
    height: 4rem;

    a {
      color: var(--black);
      display: inline-block;
      text-decoration: none;
      position: relative;
      transition: 250ms;
      font-size: 1rem;
      padding: 20px 0;

      &:nth-child(n + 2) {
        margin-left: 1.5rem;
      }

      &:hover {
        filter: brightness(.9);

        &::before {
          width: 100%;
        }
      }

      &::before {
        background-color: var(--black);
        position: absolute;
        content: "";
        transition: 250ms;
        height: 2px;
        width: 0;
        bottom: 10px;
      }
    }
  }

  .menuLink {
    color: var(--black);
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-left: 1rem;

    &:active {
      color: var(--black);
    }

    &:hover {
      color: var(--black);
    }
    
    &:nth-child(n + 2) {
      margin-top: 1rem;
    }
  }

  .menuButton {
    display: none;
  }
}

@media (max-width: 900px) {
  .header {
    flex-direction: row-reverse;
    padding: 0 1rem;
    width: 100%;

    .nav {
      display: none;
    }

    .menuButton {
      display: flex;
      margin-right: auto;
    }
  }
}

@media (min-width: 901px) and (max-width: 1699px) {
  .header {
    padding: 0 1rem;
  }
}