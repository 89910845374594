.footer {
    background-color: #454849;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 18rem;
    width: 100%;

    .paragraphtitle {
        width: 90%;
        text-align: center;
    }

    p {
        color: var(--white);

        span {
            color: var(--white);
            cursor: pointer;
            text-decoration: underline;
            font-style: italic;
            border-left: 1px solid var(--white);
            padding-left: .5rem;
            margin-left: .3rem;
        }
    }

    .inline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        width: 50%;

        .links {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 50%;
        margin-right: 5rem;

            li {
                list-style: none;

                .link {
                    color: var(--white);
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    transition: 250ms;
                    font-family: 'Inter', sans-serif;
                    font-weight: 500;
                    font-size: 1rem;
                    margin-top: .3rem;
    
                    &:hover {
                        filter: brightness(.8);
                    }
    
                    span {
                        margin-left: .5rem;
                        width: 9rem;
                    }
                }
            }
        }

        .line {
        background-color: var(--gray-500);
        width: 1px;
        height: 60%;
        }

        .newsletter {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-left: 5rem;

        span {
            color: var(--white);
            font-family: 'Inter', sans-serif;
            font-size: 1rem;
        }
        
        .inputArea {
            display: flex;
            align-items: center;
            margin-top: .3rem;

            input {
            color: var(--black);
            transition: 250ms;
            outline: 0;
            border: 1px solid;
            height: 2.3rem;
            border-radius: .3rem;
            padding-left: .7rem;

                &::placeholder {
                    color: var(--gray-200);
                }
            }

            button {
            background-color: var(--secondary);
            color: var(--black);
            transition: 250ms;
            height: 2.3rem;
            border-radius: .3rem;
            padding: 0 1rem;
            margin-left: .5rem;

                &:hover {
                    filter: brightness(.9);
                }
            }
        }

        p {
            font-size: .8rem;
            margin-top: .5rem;
        }
        }
    }
}

@media (min-width: 431px) and (max-width: 850px) {
    .footer {
        .inline {
            padding: 0 2rem;
        }
    }
}

@media (min-width: 601px) and (max-width: 850px) {
    .footer {
        padding: 0 2rem;

        .inline {
            .newsletter {
                margin-left: auto;

                .inputArea {
                    input {
                        min-width: 13rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 851px) and (max-width: 1300px) {
    .footer {
        .inline {
            justify-content: center;
            width: 100%;
            padding: 0;

            .links {
                width: 40%;
            }

            .newsletter {
                width: 60%;
            
                .inputArea {
                    input {
                        min-width: 15rem;
                    }
                }

                p {
                    width: 25rem;
                }
            }
        }
    }
}

@media (max-width: 445px) {
    .footer {
        .inline {
            width: 100%;
            padding: 0 1rem 1rem 1rem;

            .newsletter {
                .inputArea {
                    input {
                        min-width: 9rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .footer {
        height: 28rem;
  
        .paragraphtitle {
          text-align: center;
        }
  
        .inline {
          justify-content: center;
          flex-direction: column-reverse;
  
            .newsletter {
                margin-left: 0;
                min-width: 100%;
            }
    
            .links {
                min-width: 100%;
                margin: 2rem 0 0 0;
                padding: 0;
            }
        }
    }
}

@media (max-width: 850px) {
    .footer {
        .inline {
          width: 100%;
          justify-content: center;
  
          .links {
            align-items: flex-start;
            width: 30%;
          }
  
          .line {
            display: none;
          }
  
          .newsletter {
            width: 60%;
          }
        }
      }
}