.container {
    font-family: 'Roboto', sans-serif;

    .banner {
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        height: 40vh;
        padding: 0 10rem;
        margin-top: 4rem;

        .lefttext {
            color: var(--white);
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            height: 40vh;

            p {
                font-size: 1.5rem;
                width: 30rem;
                margin-top: 1rem;

                span {
                    cursor: pointer;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            
            h1 {
                font-size: 2.5rem;
                width: 35rem;
                line-height: 3rem;
            }
        }
    }

    section {
        display: flex;
        
        .content {
            padding: 2rem 4rem;
            width: 100%;

            .title {
                display: flex;
                align-items: center;
                margin-bottom: 2rem;
                
                span {
                    color: var(--black);
                    margin-left: .7rem;
                }
            }

            h2 {
                font-size: 2rem;
                line-height: 2.7rem;
            }

            .text {
                color: var(--black);
                font-size: 1.2rem;
                line-height: 1.9rem;
                width: 70%;
            }

            .contentfooter {
                display: flex;
                flex-direction: column;
                margin-top: 10rem;
                width: 17rem;

                span {
                    color: var(--black);
                    font-style: italic;
                    font-weight: bold;
                }

                .buttons {
                    display: flex;
                    align-items: center;
                    margin-top: .5rem;

                    .button {
                        background-color: var(--secondary);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: 250ms;
                        height: 2.3rem;
                        width: 8rem;
                        border-radius: .3rem;

                        &:last-child {
                            margin-left: 1rem;
                        }

                        &:hover {
                            filter: brightness(.8);
                        }

                        span {
                            color: var(--black);
                        }
                    }
                }

                .feedbackForm {
                    display: flex;
                    flex-direction: column;
                    animation: fadein 1s forwards;
                    width: 17rem;

                    textarea {
                        color: var(--black);
                        outline: 0;
                        border: 1px solid var(--gray-200);
                        margin-top: .5rem;
                        border-radius: .3rem;
                        min-width: 100%;
                        max-width: 100%;
                        height: 10rem;
                        padding: .7rem;
    
                        &:focus {
                            border-color: var(--gray-500);
                        }

                        &::placeholder {
                            color: var(--gray-200);
                        }
                    }
    
                    .sendbutton {
                        display: flex;
                        align-self: flex-end;
                        align-items: center;
                        justify-content: center;
                        transition: 250ms;
                        width: 9rem;
                        height: 2.3rem;
                        border-radius: .3rem;
                        margin-top: .5rem;
    
                        &:hover {
                            filter: brightness(.9);
                        }

                        span {
                            color: var(--black);
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media (max-width: 500px) {
    .container {
        .banner {
            .lefttext {
                align-items: center;
                width: 100%;

                
                p {
                    width: auto;
                    font-size: 1.2rem;
                }

                h1 {
                    text-align: center;
                    width: 100%;
                    font-size: 2rem;
                }
            }
        }
    }
}

@media(max-width: 750px) {
    .container {
        .banner {
            padding: 0 2rem;
        }

        section {
            .content {
                width: calc(100% - 3rem);
            }
        }
    }
}

@media (max-width: 1100px) {
    .container {
        section {
            .content {
                padding: 2rem;
                width: 100%;

                .text {
                    width: 100%;
                }
            }
        }
    }
}