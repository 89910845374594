html, body{
    padding:0px;
    margin:0px;
  }
  
  body {
    margin-top: 4rem;
  }
  
  nav{
    background-color: coral;
    padding:1.5rem;
  }
  
  nav a{
    color: white;
    font-size:2em;
  }
  
  section{
    width: 80vw;
    margin:0 auto;
    line-height: 24px;
    text-align: justify;
    min-height: 60vh;
  }
  
  h1{
    font-size:2em;
    line-height: 1.4em;
  }
  
  article{
  }
  
  figure{
    margin:0px;
  }
  
  .newsimage{
    width: 100%;
    height: auto;
  }
  
  figcaption{
    font-style: italic;
  }
  
  #previewimg{
    border:3px solid #213547;
  }
  
  
  a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
  }
  a:hover {
    color: #535bf2;
  }
  
  
  aside{
    width:340px;
    flex:0 0 340px;
    padding-left: 60px;
    padding-top: 40px;
  }
  
  .asidefigure{
    width: 300px;
    padding:20px;
    margin-bottom:20px;
    background:#eeeeee;
  }
  .asidefigure img{
    width: 300px;
    height: 300px;
  }
  .asidefigure figcaption{
    font-weight: bold;
  }
  
  footer{
    background: #213547;
    color: white;
    text-align: center;
    padding:50px 0;
    margin-top:50px;
  }
  
  #nbtdt, #nbtpreparagraph{
    margin-bottom: 20px;;
  }
  
  @media (prefers-color-scheme: light) {
    :root {
      color: #213547;
      background-color: #ffffff;
    }
  }
  
  @media(max-width: 390px) {
    footer {
      padding: 50px 5px;
    }
  }
  
  @media(min-width: 750px) and (max-width: 849px) {
    .asidefigure:nth-child(3) {
      margin-left: 10px;
    }
  }
  
  @media(max-width: 850px) {
    section {
      display: flex;
      flex-direction: column;
    }
  
    aside {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      width: 100%;
    }
  
    .asidefigure {
      width: 270px;
      padding: 20px 15px;
    }
  }