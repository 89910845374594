.container {
    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            margin-top: 1rem;
            border: 1px solid #ccc;
            padding: 0 1.5rem;
            border-radius: .5rem;

            h1 {
                font-size: 1.7rem;
                margin-right: 1rem;
                padding: 0;
                margin-block-start: 0;
                margin-block-end: 0;
            }

            .dropdown {
                background-color: #fff;
                flex-direction: column;
                position: absolute;
                border: 1px solid #ccc;
                bottom: -170px;
                left: 0;
                width: 100%;
                z-index: 999;
                border-radius: .5rem;
                animation: showdropdown .5s;

                div {
                    background-color: #fff;
                    padding: .5rem 1rem;
                    transition: .25s;

                    &:first-child {
                        border-radius: .5rem .5rem 0 0;
                    }

                    &:last-child {
                        border-radius: 0 0 .5rem .5rem;
                    }


                    &:nth-child(n + 2) {
                        border-top: 1px solid #eee;
                    }

                    &:hover {
                        filter: brightness(.9);
                    }
                }
            }
        }

        .viewType {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid var(--exeprim);
            border-radius: 1rem;
            width: 15rem;
            height: 1.8rem;
            margin-top: 2rem;

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: .25s;
                width: 7.5rem;
                padding: 0 1rem;
                height: 1.8rem;

                &:hover {
                    background-color: var(--exeprimhover);
                    
                    span {
                        color: #fff !important;
                    }
                }

                &:first-child {
                    border-top-left-radius: 1rem;
                    border-bottom-left-radius: 1rem;
                }

                &:last-child {
                    border-top-right-radius: 1rem;
                    border-bottom-right-radius: 1rem;
                }
            }

            .line {
                background-color: var(--exeprim);
                width: 1px;
                height: 1.8rem;
            }
        }

        .formato {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            .button {
                background-color: transparent;
                color: var(--exeprim);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: .25s;
                width: 4rem;
                height: 4rem;
                border: 1px solid var(--exeprim);
                border-radius: 50%;

                &:enabled {
                    &:hover {
                        background-color: var(--exeprim);
                        color: #fff;
                    }
                }

                &:disabled {
                    background-color: #ddd;
                    border-color: #ccc;
                    color: #999;
                    cursor: not-allowed;
                }
            }

            img {
                margin: 0 4rem;
            }
        }

        .buttonPreview {
            background-color: var(--exeprim);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            text-decoration: none;
            transition: .25s;
            font-size: 1.5rem;
            margin-top: 1rem;
            width: 20rem;
            height: 4rem;
            border-radius: .7rem;

            &:hover {
                filter: brightness(.9);
            }
        }
    }

    .content {
        margin-top: 4rem;
        padding: 0 3rem;
        font-size: 1.1rem;
    }

    .sizes {
        margin-top: 2rem;
        padding: 0 3rem 2rem 3rem;

        .card {
            &:nth-child(n + 2) {
                margin-top: 1.5rem;
            }

            .topbar {
                background-color: #d4dada;
                display: flex;
                align-items: center;
                height: 4rem;
                padding: 0 2rem;

                strong {
                    font-size: 1.1rem;
                }
            }

            .bottombar {
                background-color: #f5f5f5;
                display: flex;
                align-items: center;
                height: 6rem;
                padding: 0 2rem;

                .size {
                    display: flex;
                    flex-direction: column;

                    &:last-child {
                        margin-left: 50%;
                    }

                    span {
                        font-size: .8rem;
                    }

                    strong {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}

@media(max-width: 500px) {
    .container {
        .center {
            .formato {
                .button {
                    display: none !important;
                }
            }
        }

        .content {
            padding: 0 1rem;
        }

        .sizes {
            padding: 0 1rem;

            .card {
                .topbar {
                    padding: 0 1rem;
                }

                .bottombar {
                    padding: 0 1rem;
                    justify-content: space-between;

                    .size {
                        &:last-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 900px) {
    .container {
        margin-top: 4rem;
    }
}

@media(min-width: 1300px) and (max-width: 1800px) {
    .container {
        padding: 0 10rem;
    }
}

@media(min-width: 1801px) and (max-width: 2100px) {
    .container {
        padding: 0 20rem;
    }
}

@media(min-width: 2101px) {
    .container {
        padding: 0 30rem;
    }
}

@keyframes showdropdown {
    0% {opacity: 0; transform: translateY(-50%);};
    100% {opacity: 1; transform: translateY(0)};
}
